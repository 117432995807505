/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
// React Imports
import React, { useEffect } from 'react';
import { useOutletContext, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Import Components
import Hero from '../../components/Hero';
import AnchorScroll from '../../components/AnchorScroll';
import Quote from '../../components/Quote/Quote';

// Import Images
import miratiAndMe from '../../images/IMG_Yellow_MM.jpg';
import patientBrochure1 from '../../images/brochure-img-1.jpg';
import patientBrochure2 from '../../images/brochure-img-2.jpg';
import communitySupportM from '../../images/hands_green-m.png';
import communitySupportD from '../../images/hands_green-d.png';
// import handQuotation from '../../images/hand_quotation_marks.png';
// import quotes from '../../images/quotes.png';

export default function CSupport() {
  const onScroll = () => {
    const isMobile = window.innerWidth <= 1024;
    const topMargin = isMobile ? 180 : 100;
    const jumpMenu = document.querySelector('.jump-menu');
    if (!jumpMenu) {
      return;
    }
    //   const anchor1Top = document.querySelector('#mirati-and-me').getBoundingClientRect().top;
    const anchor2Top = document
      .querySelector('#downloadable-resources')
      .getBoundingClientRect().top;
    const anchor3Top = document
      .querySelector('#community-support')
      .getBoundingClientRect().top;
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    for (let li of document.querySelectorAll('.jump-menu ul li')) {
      li.classList.remove('active');
    }
    if (scrollTop >= anchor3Top + window.pageYOffset - topMargin) {
      jumpMenu.classList.remove('section-2', 'section-1');
      jumpMenu.classList.add('section-3');
      document
        .querySelector('.jump-menu ul li:nth-child(3)')
        .classList.add('active');
    } else if (scrollTop >= anchor2Top + window.pageYOffset - topMargin) {
      jumpMenu.classList.remove('section-3', 'section-1');
      jumpMenu.classList.add('section-2');
      document
        .querySelector('.jump-menu ul li:nth-child(2)')
        .classList.add('active');
    } else {
      jumpMenu.classList.remove('section-3', 'section-2');
      jumpMenu.classList.add('section-1');
      document
        .querySelector('.jump-menu ul li:nth-child(1)')
        .classList.add('active');
    }

    if (isMobile) {
      // Change text when withing sections.
    } else {
    }
  };

  const expandJumpMenu = (e) => {
    const isMobile = window.innerWidth <= 1024;
    if (isMobile) {
      if (!document.querySelector('.jump-menu').classList.contains('expand')) {
        e.preventDefault();
      }
      document.querySelector('.jump-menu').classList.toggle('expand');
      if (document.querySelector('.jump-menu').classList.contains('expand')) {
        document.querySelector('.on-this-page').innerHTML = 'ON THIS PAGE:';
      } else {
        document.querySelector('.on-this-page').innerHTML = 'CURRENT SECTION:';
      }
    }
  };

  const smoothScrollToAnchor = (e) => {
    e.preventDefault();
    const isMobile = window.innerWidth <= 1024;
    if (
      !document.querySelector('.jump-menu').classList.contains('expand') &&
      isMobile
    ) {
      return;
    }
    const target = e.target.getAttribute('data-target');
    const scrollTop = document
      .querySelector(target)
      .getBoundingClientRect().top;
    window.scrollTo({
      top: scrollTop + window.pageYOffset - (isMobile ? 190 : 85),
      left: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    document.body.className = '';
    document.body.classList.add('support', 'crc');
    window.addEventListener('scroll', onScroll);
  }, []);

  const [handleOpenModal, handleOpenFormModal] = useOutletContext();

  return (
    <>
      <Helmet>
        <title>
          Comprehensive Treatment Support & Resources
        </title>
        <meta
          name="description"
          content="Access comprehensive support for patients, including financial assistance and community resources."
        />
      </Helmet>
      <AnchorScroll />
      <Hero title="Support" subtitle="&amp;&nbsp;RESOURCES" />
      <div className="jump-menu">
        <section>
          <div className="green-bg">
            <p className="on-this-page">
              {window.innerWidth <= 1024 ? 'CURRENT SECTION:' : 'ON THIS PAGE:'}
            </p>
            <ul className="desktop-jump-menu" onClick={expandJumpMenu}>
              <li className="active">
                <a
                  href=""
                  data-target="#mirati-and-me"
                  onClick={smoothScrollToAnchor}
                >
                  MIRATI &amp; ME
                </a>
              </li>
              <li>
                <a
                  href=""
                  data-target="#downloadable-resources"
                  onClick={smoothScrollToAnchor}
                >
                  DOWNLOADABLE RESOURCES
                </a>
              </li>
              <li>
                <a
                  href=""
                  data-target="#community-support"
                  onClick={smoothScrollToAnchor}
                >
                  COMMUNITY SUPPORT
                </a>
              </li>
            </ul>
            <div className="green-arrow"></div>
          </div>
        </section>
      </div>
      <section>
        <h3>
          Explore resources to help ensure that you are never alone in your CRC
          journey.
        </h3>
        <h2 id="mirati-and-me" className="rotate-left no-wrap">
          MIRATI & ME
        </h2>
        <h3>
          Welcome to Mirati & Me, your partner for personalized support and
          access to navigate targeted treatment.
        </h3>
      </section>
      <section className="flex-container">
        <div className="flex-item">
          <img
            src={miratiAndMe}
            alt="Woman's arm holding a sign that reads Mirati & Me on a bright yellow background"
            className="mirati-me"
          />
        </div>
        <div className="flex-item">
          <p>
            <strong>
              Start your journey with assistance from a comprehensive support
              team that can help answer questions about coverage, costs, and
              care.
            </strong>
          </p>
          <p>
            Mirati Therapeutics is committed to helping you and your loved ones
            gain access to prescribed Mirati medications. When you enroll into
            the Mirati & Me Patient Support Program, you’ll receive resources
            and information to help you access your medication, manage costs,
            and navigate care, including:
          </p>
        </div>
      </section>
      <section className="list">
        <ul>
          <li>
            <strong>Coverage and Access Support</strong>
            <br />
            Mirati & Me will help you understand your insurance coverage and how
            much you may need to pay for your medications.
          </li>
          <li>
            <strong>Financial Support*</strong>
            <br />
            <ul>
              <li>
                Mirati & Me may provide you with assistance for paying
                deductibles, co-pays, and/or coinsurance for prescribed Mirati
                medications. If eligible, you can pay as little as $0 out of
                pocket
              </li>
              <li>
                If you are not insured or are under-insured,{' '}
                <span className="nowrap">Mirati & Me</span> may be able to
                provide assistance in the form of free product
                <sup>†</sup>
              </li>
              <li>
                Mirati & Me may provide a free 30-day supply of Mirati
                prescription medications to help determine if therapy is right
                for you<sup>‡</sup>
              </li>
              <li>
                If you are experiencing a delay or lapse in insurance coverage,
                there may be financial assistance for an appropriate supply of
                medication
              </li>
            </ul>
          </li>
          <li>
            <strong>Educational Support</strong>
            <br />
            Learn what to expect with treatment and how to prepare for the
            treatment plan that you and your healthcare provider have
            established.
          </li>
          <li>
            <strong>Emotional Support</strong>
            <br />
            Independent organizations, as well as local and online support
            groups, may provide counseling and emotional assistance to help you
            through your journey.
          </li>
          <li>
            <strong>Additional Support</strong>
            <br />
            Independent organizations may be available to help you with co-pay
            assistance,<sup>§</sup> as well as educational and emotional
            support.
          </li>
          <li>
            <p className="footnotes asterisk">
              Restrictions and conditions apply.
            </p>
            <p className="footnotes dagger">
              Annual family gross income equal to or less than $150,000 for
              household size of 2 people; $25,000 per additional person in
              household. Additional conditions and restrictions apply.
            </p>
            <p className="footnotes doubledagger">
              One-time offer per patient (per lifetime). Certain eligibility
              criteria and restrictions apply.
            </p>
            <p className="footnotes doubles">
              Individual organizations determine patient eligibility.
            </p>
          </li>
        </ul>
      </section>
      <section className="purple-banner">
        <div className="content-wrapper">
          <p>Getting started is easy.</p>{' '}
          <p>
            Visit{' '}
            <a
              href="https://miratiandme.com"
              onClick={(e) => {
                e.preventDefault();
                handleOpenModal('https://miratiandme.com');
              }}
            >
              MiratiAndMe.com
            </a>{' '}
            or call&nbsp;
            <a href="tel:+18446472842">
              <span className="underline no-wrap">1.844.647.2842&nbsp;</span>
              <span className="no-wrap">(1.844.MIRATI2)</span>
            </a>
          </p>{' '}
          <p>
            8 <span>AM</span>-8 <span>PM</span> ET Mon-Fri
          </p>
        </div>
      </section>
      <section id="resources">
        <h2 id="downloadable-resources" className="rotate-left no-wrap">
          DOWNLOADABLE RESOURCES
        </h2>
        <h3>
          Download this document to guide you through your treatment with
          KRAZATI.
        </h3>
        <div className="downloads-container">
          <div className="download">
            <img
              src={patientBrochure1}
              alt="Image of Company's patient brochure cover"
            />
            <img
              src={patientBrochure2}
              alt="Image of Company's patient brochure opened"
              className="mobile-only"
            />
            <p>KRAZATI CRC Patient Brochure</p>
            <Link to="/files/patient-brochure.pdf" target="_blank">
              Download Now &gt;
            </Link>
          </div>
          <div className="download">
            <img
              src={patientBrochure2}
              alt="Image of Company's patient brochure opened"
              className="desktop-only"
            />
          </div>
        </div>
        <p className="mobile-mb5">
          Please call{` `}
          <a href="tel:+18446472841">
            <span className="no-wrap">1-844-MIRATI-1</span>{' '}
            <span className="no-wrap">(1-844-647-2841)</span>
          </a>
          {` `}to speak with a Medical Information Specialist about KRAZATI or
          to report an adverse event/product complaint. You can also directly report an adverse event/product complaint by
          clicking{' '}
          <a
            href="https://miratidrugsafety.com/ConsumerForm"
            target="blank"
            rel="noreferrer"
            className="bold"
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal('https://miratidrugsafety.com/ConsumerForm');
            }}
          >
            here
          </a>
          .
        </p>
      </section >
      <section className="discover-a-community">
        <h2 id="community-support" className="rotate-left no-wrap">
          COMMUNITY SUPPORT
        </h2>
        <h3>
          Discover additional resources and a community of support through the
          links below.
        </h3>
        <img
          className="mobile-only"
          src={communitySupportM}
          alt="Two fists bumping against a green background"
        />
        <img
          className="desktop-only"
          src={communitySupportD}
          alt="Two fists bumping against a green background"
        />
      </section>
      <section className="support-links">
        <div className="container-wrapper">
          <ul>
            <li>
              <a
                href="https://colorectalcancer.org/"
                target="_blank"
                rel="noreferrer"
                onClick={(e) => {
                  e.preventDefault();
                  handleOpenModal('https://colorectalcancer.org/');
                }}
              >
                Colorectal Cancer Alliance
              </a>
            </li>
            <li>
              <a
                href="https://colontown.org/"
                target="_blank"
                rel="noreferrer"
                onClick={(e) => {
                  e.preventDefault();
                  handleOpenModal('https://colontown.org/');
                }}
              >
                Colontown/Paltown
              </a>
            </li>
            <li>
              <a
                href="https://www.globalcca.org/"
                target="_blank"
                rel="noreferrer"
                onClick={(e) => {
                  e.preventDefault();
                  handleOpenModal('https://www.globalcca.org/');
                }}
              >
                Global Colon Cancer Association
              </a>
            </li>
            <li>
              <a
                href="https://fightcolorectalcancer.org/"
                target="_blank"
                rel="noreferrer"
                onClick={(e) => {
                  e.preventDefault();
                  handleOpenModal('https://fightcolorectalcancer.org/');
                }}
              >
                Fight Colorectal Cancer
              </a>
            </li>
          </ul>
          <ul>
            <li>
              <a
                href="https://www.theraymondfoundation.org/"
                target="_blank"
                rel="noreferrer"
                onClick={(e) => {
                  e.preventDefault();
                  handleOpenModal('https://www.theraymondfoundation.org/');
                }}
              >
                The Raymond Foundation
              </a>
            </li>
            <li>
              <a
                href="https://www.gicancersalliance.org/"
                target="_blank"
                rel="noreferrer"
                onClick={(e) => {
                  e.preventDefault();
                  handleOpenModal('https://www.gicancersalliance.org/');
                }}
              >
                GI Cancers Alliance
              </a>
            </li>
            <li>
              <a
                href="https://coloncancercoalition.org/"
                target="_blank"
                rel="noreferrer"
                onClick={(e) => {
                  e.preventDefault();
                  handleOpenModal('https://coloncancercoalition.org/');
                }}
              >
                Colon Cancer Coalition
              </a>
            </li>
          </ul>
        </div>
      </section>
      <section>
        <p className="reduce-line-height">
          Mirati Therapeutics, Inc., a Bristol Myers Squibb company, does not
          endorse any particular group or service and is not responsible for the
          content of these sites or services.
        </p>
        <p className="footnotes">CRC=colorectal cancer.</p>
      </section>
    </>
  );
}

// React Imports
import React, { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

// Component Imports
import Violator from "../components/Violator";
import Header from "../components/Header";
import Isi from "../components/Isi";
import Footer from "../components/Footer";
import InterstitialModal from "../components/InterstitialModal";
import BMSModal from "../components/BMSModal";
import RotateModal from "../components/RotateModal";
import StayConnectedModal from "../components/StayConnectedModal";

// Library Imports
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles

export default function App() {
  const { pathname, hash } = useLocation();
  const navigate = useNavigate();
  const stayConnectedHash = "#stay-connected";

  // Initialize State values for modals
  const [link, modalLink] = useState("");
  const [violator, showViolator] = useState(0);
  const [modal, showModal] = useState(0);
  const [rotateModal, showRotateModal] = useState(0);
  const [formModal, showFormModal] = useState(false);
  const [bmsModal, showBmsModal] = useState(false);
  const [siteSection, setSiteSection] = useState("");
  const [indication, setIndication] = useState("");
  useEffect(() => {
    handleOpenViolator();
    handleOpenRotateModal();
    AOS.init({
      duration: 1500,
      delay: 0,
    });
    const siteSection = window.location.pathname.split("/")[1];
    if (siteSection) {
      setSiteSection(siteSection);
    }
  });

  // Open Violator
  function handleOpenViolator() {
    // Show Violator if it hasnt been closed before
    if (sessionStorage.getItem("violatorClosed") !== "true") {
      showViolator(1);
      document.body.classList.add("violator-open");
    } else {
      handleCloseViolator();
    }
  }

  // Close Violator
  function handleCloseViolator() {
    showViolator(0);
    sessionStorage.setItem("violatorClosed", true);
    document.body.classList.remove("violator-open");
  }

  // Open Modal
  function handleOpenModal(link) {
    console.log(link);
    // Show modal and set link value
    showModal(1);
    modalLink(link);
    setTimeout(() => {
      const modalOverlay = document.querySelector(".ReactModal__Overlay");
      const html = document.querySelector("html");
      if (modalOverlay) {
        modalOverlay.classList.add("show");
      }
      html.classList.add("no-scroll");
      modalOverlay.addEventListener("click", (e) => {
        if (e.target !== modalOverlay) {
          return;
        }
        handleCloseModal();
      });
    }, 100);
  }

  // Close modal
  function handleCloseModal() {
    showModal(0);
    const modalOverlay = document.querySelector(".ReactModal__Overlay");
    const html = document.querySelector("html");
    if (modalOverlay) {
      modalOverlay.classList.remove("show");
    }
    html.classList.remove("no-scroll");
  }

  // Close BMS modal
  function handleCloseBmsModal() {
    // Set cookie to not show modal again.
    document.cookie =
      "bms_modal=shown;max-age=2592000;domain=" + window.location.hostname;
    showBmsModal(0);
    const modalOverlay = document.querySelector(".ReactModal__Overlay");
    const html = document.querySelector("html");
    if (modalOverlay) {
      modalOverlay.classList.remove("show");
    }
    html.classList.remove("no-scroll");
  }

  // Open Rotate Modal
  function handleOpenRotateModal() {
    let portrait = window.matchMedia("(orientation: portrait)");

    portrait.addEventListener("change", function (e) {
      if (e.matches) {
        // Portrait mode
        handleCloseRotateModal();
      } else {
        // Landscape
        if (window.innerWidth < 1024) {
          openRotateModal();
        }
      }
    });

    window.addEventListener("resize", function () {
      if (window.innerWidth > 1024) {
        handleCloseRotateModal();
      }
    });
  }

  function openRotateModal() {
    // Show modal
    showRotateModal(1);
    setTimeout(() => {
      const modalOverlay = document.querySelector(".ReactModal__Overlay");
      const html = document.querySelector("html");
      if (modalOverlay) {
        modalOverlay.classList.add("show");
      }
      html.classList.add("no-scroll");
      if (modalOverlay) {
        modalOverlay.addEventListener("click", (e) => {
          if (e.target !== modalOverlay) {
            return;
          }
          handleCloseRotateModal();
        });
      }
    }, 100);
  }

  // Close Rotate Modal
  function handleCloseRotateModal() {
    showRotateModal(0);
    const modalOverlay = document.querySelector(".ReactModal__Overlay");
    const html = document.querySelector("html");
    if (modalOverlay) {
      modalOverlay.classList.remove("show");
    }
    html.classList.remove("no-scroll");
  }

  // listen for hash change
  useEffect(() => {
    hash === stayConnectedHash ? showFormModal(true) : showFormModal(false);
  }, [hash]);

  function toggleFormModal() {
    navigate(stayConnectedHash);
    showFormModal(true);

    if (hash === stayConnectedHash) {
      navigate(pathname);
      showFormModal(false);
    }
  }

  useEffect(() => {
    const body = document.querySelector("body");
    const interval = setInterval(() => {
      const CB = document.getElementById("CybotCookiebotDialog");

      if (!CB) return;

      const dataTemplate = CB?.getAttribute("data-template");
      if (dataTemplate && dataTemplate === "pushdown") {
        body.style.paddingTop = `${CB ? CB.offsetHeight : 0}px`;
      } else {
        body.style.paddingTop = "0px";
      }
    }, 300);

    const bms_cookie = document.cookie.replace(
      /(?:(?:^|.*;\s*)bms_modal\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
    if (bms_cookie !== "shown") {
      showBmsModal(1);
    }

    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <div className="wrapper">
        <div className="content">
          <Violator
            openViolator={handleOpenViolator}
            closeViolator={handleCloseViolator}
            violator={violator}
          />
          <Header openModal={handleOpenModal} openFormModal={toggleFormModal} />
          <Outlet context={[handleOpenModal, toggleFormModal]} />
          <Isi openModal={handleOpenModal} />
          <Footer openModal={handleOpenModal} />
          <InterstitialModal
            closeModal={handleCloseModal}
            modal={modal}
            link={link}
          />
          <BMSModal
            closeModal={handleCloseBmsModal}
            modal={bmsModal}
            link={link}
          />
          <RotateModal
            closeModal={handleCloseRotateModal}
            modal={rotateModal}
          />
          <StayConnectedModal
            handleModal={toggleFormModal}
            modalIsOpen={formModal}
          />
        </div>
      </div>
    </>
  );
}

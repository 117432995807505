// React Imports
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

// Library Imports
import ReactModal from "react-modal";
import Cleave from "cleave.js/react";

// Image Imports
import x from "../images/icon_close.svg";
import xwhite from "../images/icon_close_white.svg";

export default function StayConnectedModal(props) {
  const [finishedSubmission, setFinishedSubmission] = useState(false);
  const [errorOnSubmission, setErrorOnSubmission] = useState(false);

  const { register, setValue } = useForm({ mode: "onChange" });

  useEffect(() => {
    const startSession = async () => {
      const api = await fetch("/api/session.php", {
        method: "POST",
      });
      const response = await api.json();

      setValue("session_key", response);
    };

    startSession();
  }, [setValue]);

  // const [customValue, setCustomValue] = useState("");
  function handleScroll() {
    const container = document.querySelector(".form-modal .scroll-container");
    let topPos = container.scrollTop;
    let logoPos = "-" + topPos + "px";
    let style = document.querySelector(".form-modal").style;
    topPos = topPos + 6;
    style.setProperty("--top", logoPos);

    if (topPos > 50) {
      style.setProperty("--visible", 0);
    } else {
      style.setProperty("--visible", 1);
    }

    const x = document.querySelector("img.x");
    const whitex = document.querySelector("img.x-white");
    let headerHeight;
    if (window.innerWidth > 1023) {
      headerHeight = 283;
    } else if (window.innerWidth > 418) {
      headerHeight = 173;
    } else {
      headerHeight = 198;
    }
    if (topPos > headerHeight) {
      x.classList.remove("show");
      whitex.classList.add("show");
    } else {
      x.classList.add("show");
      whitex.classList.remove("show");
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    // Creates an array of form data
    let urlEncodedArr = [];
    for (let i = 0; i < e.target.length; i++) {
      const el = e.target[i];
      if ((el.type === "radio" && !el.checked) || el.type === "submit") {
        continue;
      }
      if (el.type === "checkbox") {
        urlEncodedArr.push(
          el.name + "=" + encodeURIComponent(el.value === "on" ? true : false)
        );
      } else {
        urlEncodedArr.push(el.name + "=" + encodeURIComponent(el.value));
      }
    }

    // Convert arr to string
    const urlEncodedStr = urlEncodedArr.join("&");
    // console.log(urlEncodedStr);

    // POST string to backend
    fetch("/api/api.php", {
      body: urlEncodedStr,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      method: "post",
    })
      // .then((response) => {
      //   console.log(response);
      //   setFinishedSubmission(true);
      //   setErrorOnSubmission(true);
      // });
      .then((response) => response.json())
      .then((response) => {
        if (response.status !== "success") {
          // add error stuff here
          console.log(response.status);
          setFinishedSubmission(true);
          setErrorOnSubmission(true);
        } else {
          // Show thank you if no errors
          console.log(response.status);
          setFinishedSubmission(true);
        }
      });
    setFinishedSubmission(true);
  };

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={props.modalIsOpen}
      onRequestClose={props.handleModal}
      shouldCloseOnOverlayClick={true}
      contentLabel="Form Modal"
      className="form-modal"
    >
      <img className="x show" src={x} alt="x" onClick={props.handleModal} />
      <img
        className="x-white"
        src={xwhite}
        alt="x"
        onClick={props.handleModal}
      />

      <div className="scroll-container" onScroll={handleScroll}>
        <p className="subtitle">a place</p>
        <p>for you, focused on you</p>
        <p className="black">
          Stay connected with KRAZATI. Fill out the form below to receive
          information to help you along your NSCLC treatment journey.
        </p>

        {finishedSubmission ? (
          errorOnSubmission ? (
            <div className="submitted-container">
              <p>
                Oops!
                <br />
                Please try submitting again.
              </p>
            </div>
          ) : (
            <div className="submitted-container">
              <p>Thank you for registering!</p>
            </div>
          )
        ) : (
          <form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <div className="flex-container">
              <input
                type="hidden"
                id="session_key"
                {...register("session_key", { required: false })}
              />
              <div className="flex-item">
                <label className="required" htmlFor="fname">
                  First Name
                </label>
                <input name="fname" id="fname" type="text" required />
              </div>

              <div className="flex-item">
                <label className="required" htmlFor="lname">
                  Last Name
                </label>
                <input name="lname" id="lname" type="text" required />
              </div>

              <div className="radios" id="about">
                <label htmlFor="about">About Me (Select One)</label>
                <input
                  type="radio"
                  id="considering"
                  name="about"
                  value="I am considering KRAZATI"
                  defaultChecked
                  required
                />
                <label htmlFor="considering" className="bb">
                  I am considering KRAZATI
                </label>
                <input
                  type="radio"
                  id="prescribed"
                  name="about"
                  value="I am currently prescribed KRAZATI"
                  required
                />
                <label htmlFor="prescribed" className="bb">
                  I am currently prescribed KRAZATI
                </label>
                <input
                  type="radio"
                  id="partner"
                  name="about"
                  value="I am a care partner for someone taking KRAZATI"
                  required
                />
                <label htmlFor="partner">
                  I am a care partner for someone taking KRAZATI
                </label>
              </div>

              <div className="flex-item">
                <label className="required" htmlFor="email">
                  Email Address
                </label>
                <input
                  name="email"
                  id="email"
                  type="email"
                  pattern="^[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                  required
                />

                <label htmlFor="phone">Phone Number</label>
                <Cleave
                  name="phone"
                  id="phone"
                  options={{
                    blocks: [0, 3, 0, 3, 4],
                    delimiters: ["(", ")", " ", "-"],
                    numericOnly: true,
                  }}
                />
              </div>

              <p className="footnotes">*Required.</p>

              <input
                className="submitButton button purple desktop-only"
                type="submit"
                value="SUBMIT"
              />

              <p className="mobile-only terms">
                By clicking "Submit," you agree to disclose your personal
                information to Mirati and to be contacted by Mirati and its
                agents in the future with promotional communications relating to
                Mirati's products, services, and/or information related to
                KRAZATI. For more information, please consult our{" "}
                <a
                  href="https://www.bms.com/privacy-policy.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Use
                </a>{" "}
                and{" "}
                <a
                  href="https://www.bms.com/privacy-policy.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </p>

              <input
                className="submitButton button purple mobile-only"
                type="submit"
                value="SUBMIT"
              />
            </div>
            <p className="desktop-only terms">
              By clicking "Submit," you agree to disclose your personal
              information to Mirati and to be contacted by Mirati and its agents
              in the future with promotional communications relating to Mirati's
              products, services, and/or information related to KRAZATI. For
              more information, please consult our{" "}
              <a
                href="https://www.mirati.com/terms-of-use/"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Use
              </a>{" "}
              and{" "}
              <a
                href="https://www.bms.com/privacy-policy.html"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              .
            </p>
          </form>
        )}
      </div>
    </ReactModal>
  );
}

/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
// React Imports
import React, { useEffect } from "react";
import { useOutletContext, Link } from "react-router-dom";
import { Helmet } from "react-helmet";

// Import Components
import Hero from "../../components/Hero";
import AnchorScroll from "../../components/AnchorScroll";
import Quote from "../../components/Quote/Quote";

// Import Images
import miratiAndMe from "../../images/mirati_and_me.jpg";
// import patientBrochure from "../../images/brochure_thumbnail.png";
// import faqSheet from "../../images/starting_krazati.png";
import patientBrochure from "../../images/Patient_Brochure_Coming_Soon_12_6_2022.jpg";
import faqSheet from "../../images/FAQ_Coming_Soon_12_6_2022.jpg";
import communitySupportM from "../../images/hands_green-m.png";
import communitySupportD from "../../images/hands_green-d.png";
import handQuotation from "../../images/hand_quotation_marks.png";
// import quotes from '../../images/quotes.png';

export default function NSupport() {
  const onScroll = () => {
    const isMobile = window.innerWidth <= 1024;
    const topMargin = isMobile ? 180 : 100;
    const jumpMenu = document.querySelector(".jump-menu");
    if (!jumpMenu) {
      return;
    }
    //   const anchor1Top = document.querySelector('#mirati-and-me').getBoundingClientRect().top;
    const anchor2Top = document
      .querySelector("#downloadable-resources")
      .getBoundingClientRect().top;
    const anchor3Top = document
      .querySelector("#community-support")
      .getBoundingClientRect().top;
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    for (let li of document.querySelectorAll(".jump-menu ul li")) {
      li.classList.remove("active");
    }
    if (scrollTop >= anchor3Top + window.pageYOffset - topMargin) {
      jumpMenu.classList.remove("section-2", "section-1");
      jumpMenu.classList.add("section-3");
      document
        .querySelector(".jump-menu ul li:nth-child(3)")
        .classList.add("active");
    } else if (scrollTop >= anchor2Top + window.pageYOffset - topMargin) {
      jumpMenu.classList.remove("section-3", "section-1");
      jumpMenu.classList.add("section-2");
      document
        .querySelector(".jump-menu ul li:nth-child(2)")
        .classList.add("active");
    } else {
      jumpMenu.classList.remove("section-3", "section-2");
      jumpMenu.classList.add("section-1");
      document
        .querySelector(".jump-menu ul li:nth-child(1)")
        .classList.add("active");
    }

    if (isMobile) {
      // Change text when withing sections.
    } else {
    }
  };

  const expandJumpMenu = (e) => {
    const isMobile = window.innerWidth <= 1024;
    if (isMobile) {
      if (!document.querySelector(".jump-menu").classList.contains("expand")) {
        e.preventDefault();
      }
      document.querySelector(".jump-menu").classList.toggle("expand");
      if (document.querySelector(".jump-menu").classList.contains("expand")) {
        document.querySelector(".on-this-page").innerHTML = "ON THIS PAGE:";
      } else {
        document.querySelector(".on-this-page").innerHTML = "CURRENT SECTION:";
      }
    }
  };

  const smoothScrollToAnchor = (e) => {
    e.preventDefault();
    const isMobile = window.innerWidth <= 1024;
    if (
      !document.querySelector(".jump-menu").classList.contains("expand") &&
      isMobile
    ) {
      return;
    }
    const target = e.target.getAttribute("data-target");
    const scrollTop = document
      .querySelector(target)
      .getBoundingClientRect().top;
    window.scrollTo({
      top: scrollTop + window.pageYOffset - (isMobile ? 190 : 85),
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    document.body.className = "";
    document.body.classList.add("support", "nsclc");
    window.addEventListener("scroll", onScroll);
  }, []);

  const [handleOpenModal, handleOpenFormModal] = useOutletContext();

  return (
    <>
      <Helmet>
        <title>
          KRAZATI&reg; (adagrasib) Support & Resources | Patient Support
        </title>
        <meta
          name="description"
          content="Access comprehensive support for KRAZATI&reg;. Get assistance with coverage and care. Download additional resources, including the Patient Brochure and FAQs."
        />
      </Helmet>
      <AnchorScroll />
      <Hero title="Support" subtitle="&amp;&nbsp;RESOURCES" />
      <div className="jump-menu">
        <section>
          <div className="green-bg">
            <p className="on-this-page">
              {window.innerWidth <= 1024 ? "CURRENT SECTION:" : "ON THIS PAGE:"}
            </p>
            <ul className="desktop-jump-menu" onClick={expandJumpMenu}>
              <li className="active">
                <a
                  href=""
                  data-target="#mirati-and-me"
                  onClick={smoothScrollToAnchor}
                >
                  MIRATI &amp; ME
                </a>
              </li>
              <li>
                <a
                  href=""
                  data-target="#downloadable-resources"
                  onClick={smoothScrollToAnchor}
                >
                  DOWNLOADABLE RESOURCES
                </a>
              </li>
              <li>
                <a
                  href=""
                  data-target="#community-support"
                  onClick={smoothScrollToAnchor}
                >
                  COMMUNITY SUPPORT
                </a>
              </li>
            </ul>
            <div className="green-arrow"></div>
          </div>
        </section>
      </div>
      <section>
        <h3>
          Explore resources to help ensure that you are never alone in your
          NSCLC journey.
        </h3>
        <h2 id="mirati-and-me" className="rotate-left no-wrap">
          MIRATI & ME
        </h2>
        <h3>
          Welcome to Mirati & Me, your partner for personalized support and
          access to navigate targeted treatment.
        </h3>
      </section>
      <section className="flex-container">
        <div className="flex-item">
          <img
            src={miratiAndMe}
            alt="Woman's arm holding a sign that reads Mirati & Me on a bright yellow background"
          />
        </div>
        <div className="flex-item">
          <p>
            <strong>
              Start your journey with assistance from a comprehensive support
              team that can help answer questions about coverage, costs, and
              care.
            </strong>
          </p>
          <p>
            Mirati Therapeutics is committed to helping you and your loved ones
            gain access to prescribed Mirati medications. When you enroll into
            the Mirati & Me Patient Support Program, you’ll receive resources
            and information to help you access your medication, manage costs,
            and navigate care, including:
          </p>
        </div>
      </section>
      <section className="list">
        <ul>
          <li>
            <strong>Coverage and Access Support</strong>
            <br />
            Mirati & Me will help you understand your insurance coverage and how
            much you may need to pay for your medications.
          </li>
          <li>
            <strong>Financial Support*</strong>
            <br />
            <ul>
              <li>
                Mirati & Me may provide you with assistance for paying
                deductibles, co-pays, and/or coinsurance for prescribed Mirati
                medications. If eligible, you can pay as little as $0 out of
                pocket
              </li>
              <li>
                If you are not insured or are under-insured,{" "}
                <span className="nowrap">Mirati & Me</span> may be able to
                provide assistance in the form of free product
                <sup>†</sup>
              </li>
              <li>
                Mirati & Me may provide a free 30-day supply of Mirati
                prescription medications to help determine if therapy is right
                for you<sup>‡</sup>
              </li>
              <li>
                If you are experiencing a delay or lapse in insurance coverage,
                there may be financial assistance for an appropriate supply of
                medication
              </li>
            </ul>
          </li>
          <li>
            <strong>Educational Support</strong>
            <br />
            Learn what to expect with treatment and how to prepare for the
            treatment plan that you and your healthcare provider have
            established.
          </li>
          <li>
            <strong>Emotional Support</strong>
            <br />
            Independent organizations, as well as local and online support
            groups, may provide counseling and emotional assistance to help you
            through your journey.
          </li>
          <li>
            <strong>Additional Support</strong>
            <br />
            Independent organizations may be available to help you with co-pay
            assistance,<sup>§</sup> as well as educational and emotional
            support.
          </li>
          <p className="footnotes asterisk">
            Restrictions and conditions apply.
          </p>
          <p className="footnotes dagger">
            Annual family gross income equal to or less than $150,000 for
            household size of 2 people; $25,000 per additional person in
            household. Additional conditions and restrictions apply.
          </p>
          <p className="footnotes doubledagger">
            One-time offer per patient (per lifetime). Certain eligibility
            criteria and restrictions apply.
          </p>
          <p className="footnotes doubles">
            Individual organizations determine patient eligibility.
          </p>
        </ul>
      </section>
      <section className="purple-banner">
        <div className="content-wrapper">
          <p>Getting started is easy.</p>{" "}
          <p>
            Visit{" "}
            <a
              href="https://miratiandme.com"
              onClick={(e) => {
                e.preventDefault();
                handleOpenModal("https://miratiandme.com");
              }}
            >
              MiratiAndMe.com
            </a>{" "}
            or call&nbsp;
            <a href="tel:+18446472842">
              <span className="underline no-wrap">1.844.647.2842&nbsp;</span>
              <span className="no-wrap">(1.844.MIRATI2)</span>
            </a>
          </p>{" "}
          <p>
            8 <span>AM</span>-8 <span>PM</span> ET Mon-Fri
          </p>
        </div>
      </section>
      <section id="resources">
        <h2 id="downloadable-resources" className="rotate-left no-wrap">
          DOWNLOADABLE RESOURCES
        </h2>
        <h3>
          Download these documents to guide you through your treatment with
          KRAZATI.
        </h3>
        <div className="downloads-container">
          <div className="download">
            <img
              src={patientBrochure}
              alt="KRAZATI - adagrasib patient brochure"
            />
            <p>KRAZATI Patient Brochure&nbsp;</p>
            <Link to="/n/404">English Version Download Now &gt;</Link>
            <Link to="/n/404">Spanish Version Download Now &gt;</Link>
          </div>
          <div className="download">
            <img
              src={faqSheet}
              alt="Image of downloadable KRAZATI - adagrasib FAQs paper"
            />
            <p>Downloadable KRAZATI FAQs&nbsp;</p>
            <Link to="/files/downloadable-faqs.pdf" target="_blank">
              Download Now &gt;
            </Link>
          </div>
        </div>
        <p className="mobile-mb5">
          Please call{` `}
          <a className="no-style" href="tel:+18446472841">
            <span className="no-wrap">1-844-MIRATI-1</span>{" "}
            <span className="no-wrap">(1-844-647-2841)</span>
          </a>
          {` `}to speak with a Medical Information Specialist about KRAZATI or
          to report an adverse event/product complaint. <br />
        </p>
        <p>
          You can also directly report an adverse event/product complaint by
          clicking{" "}
          <strong>
            <a
              href="https://miratidrugsafety.com/ConsumerForm"
              target="blank"
              rel="noreferrer"
              onClick={(e) => {
                e.preventDefault();
                handleOpenModal("https://miratidrugsafety.com/ConsumerForm");
              }}
            >
              here
            </a>
          </strong>
          .
        </p>
        <div id="center-cta" className="ctas">
          <Link
            to="#stay-connected"
            className="cta"
            onClick={(e) => {
              e.preventDefault();
              handleOpenFormModal();
            }}
          >
            <p className="cta-header">START YOUR NON-SMALL REVOLUTION NOW</p>
            <p>
              <span className="after-arrow">
                Sign up to receive information to help you along your treatment
                journey
              </span>
            </p>
          </Link>
        </div>
      </section>
      <section className="discover-a-community">
        <h2 id="community-support" className="rotate-left no-wrap">
          COMMUNITY SUPPORT
        </h2>
        <h3>
          Discover additional resources and a community of support through the
          links below.
        </h3>
        <img
          className="mobile-only"
          src={communitySupportM}
          alt="Two fists bumping against a green background"
        />
        <img
          className="desktop-only"
          src={communitySupportD}
          alt="Two fists bumping against a green background"
        />
      </section>
      <section className="support-links">
        <div className="support-link">
          <h4>Cancer Support Community</h4>
          <p>
            As the largest professionally led nonprofit network of cancer
            support worldwide, Cancer Support Community (CSC) is dedicated to
            ensuring that all people impacted by cancer are empowered by
            knowledge, strengthened by action, and sustained by community.
          </p>
          <a
            href="https://www.cancersupportcommunity.org/get-educated-inspired"
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal(
                "https://www.cancersupportcommunity.org/get-educated-inspired"
              );
            }}
          >
            Educational Support &gt;
          </a>
          <a
            href="https://www.cancersupportcommunity.org/managing-side-effects"
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal(
                "https://www.cancersupportcommunity.org/managing-side-effects"
              );
            }}
          >
            Emotional Support &gt;
          </a>
        </div>

        <div className="support-link">
          <h4>CancerCare</h4>
          <p>
            CancerCare is the leading national organization providing free,
            professional support services and information to help people manage
            the emotional, practical, and financial challenges of cancer.
          </p>
          <a
            href="https://www.cancercare.org/services"
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal("https://www.cancercare.org/services");
            }}
          >
            Educational/Emotional Support &gt;
          </a>
        </div>

        <div className="support-link">
          <h4>
            GO<sub>2</sub> Foundation for Lung Cancer
          </h4>
          <p>
            Founded by patients and survivors, GO<sub>2</sub> Foundation for
            Lung Cancer transforms survivorship as the world’s leading
            organization dedicated to saving, extending, and improving the lives
            of those vulnerable, at risk, and diagnosed with lung cancer.
          </p>
          <a
            href="https://go2foundation.org/resources-and-support/general-support/educational-materials/download-educational-materials/"
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal(
                "https://go2foundation.org/resources-and-support/general-support/educational-materials/download-educational-materials/"
              );
            }}
          >
            Educational Support &gt;
          </a>
          <a
            href="https://go2foundation.org/resources-and-support/general-support/helpline/"
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal(
                "https://go2foundation.org/resources-and-support/general-support/helpline/"
              );
            }}
          >
            Emotional Support &gt;
          </a>
        </div>

        <div className="support-link">
          <h4>Lungevity Foundation</h4>
          <p>
            Lungevity Foundation is the nation’s leading lung cancer
            organization focused on improving outcomes for people with lung
            cancer through research, education, policy initiatives, and support
            for patients, survivors, and caregivers.
          </p>
          <a
            href="https://www.lungevity.org/for-patients-caregivers"
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal(
                "https://www.lungevity.org/for-patients-caregivers"
              );
            }}
          >
            Educational/Emotional Support &gt;
          </a>
        </div>

        <div className="support-link">
          <h4>KRAS Kickers</h4>
          <p>
            A nonprofit for KRAS oncogene patients, survivors, and their loved
            ones. We bring together research, information, and hope for all with
            a KRAS biomarker, inspiring our community to fight in our common
            battle.
          </p>
          <a
            href="https://www.kraskickers.org/services-4"
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal("https://www.kraskickers.org/services-4");
            }}
          >
            Educational Support &gt;
          </a>
          <a
            href="https://www.kraskickers.org/services-1"
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal("https://www.kraskickers.org/services-1");
            }}
          >
            Emotional Support &gt;
          </a>
        </div>

        <div className="support-link">
          <h4>
            Lung Cancer Research Foundation<sup>&reg;</sup>
          </h4>
          <p>
            The mission of Lung Cancer Research Foundation (LCRF) is to improve
            lung cancer outcomes by funding research for the prevention,
            diagnosis, treatment, and cure of lung cancer.
          </p>
          <a
            href="https://www.lungcancerresearchfoundation.org/for-patients/quick-links-for-patients-and-caregivers/"
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal(
                "https://www.lungcancerresearchfoundation.org/for-patients/quick-links-for-patients-and-caregivers/"
              );
            }}
          >
            Educational Support &gt;
          </a>
          <a
            href="https://www.lungcancerresearchfoundation.org/for-patients/living-with-lung-cancer/coping-with-emotions-upon-diagnosis/"
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal(
                "https://www.lungcancerresearchfoundation.org/for-patients/living-with-lung-cancer/coping-with-emotions-upon-diagnosis/"
              );
            }}
          >
            Emotional Support &gt;
          </a>
        </div>

        <div className="support-link">
          <h4>One Cancer Place</h4>
          <p>
            One Cancer Place, the first patient-powered cancer learning
            community, is on a mission to create a global, all-cancer,
            patient-led learning community connected to innovative treatment
            offerings.
          </p>
          <a
            href="https://www.onecancerplace.org/"
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal("https://www.onecancerplace.org/");
            }}
          >
            Educational/Emotional Support &gt;
          </a>
        </div>
      </section>
      <section className="npt">
        <p className="footnotes">NSCLC=non-small cell lung cancer.</p>
        <p>
          Mirati does not endorse any particular group or service and is not
          responsible for the content of these sites or services.
        </p>
      </section>
      {/* <section className='quote blue'>
                <div className='content-wrapper'>
                    <img className='quotes hands' src={handQuotation} alt='Hand Quotation Marks' />
                    <img className='quotes no-hands' src={quotes} alt='Quotation Marks' />
                    <p>
                        I want to know that I matter, and I absolutely feel like I matter to&nbsp;Mirati.”
                        <span className='name'>KRAZATI TRIAL PARTICIPANT</span>
                    </p>
                </div>
            </section> */}
      <Quote
        text="I want to know that I matter, and I absolutely feel like I matter to&nbsp;Mirati."
        author="KRAZATI TRIAL PARTICIPANT"
        image={handQuotation}
      />
    </>
  );
}

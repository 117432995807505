// React Imports
import React from "react";

export default function IsiContent({ isCollapsed }) {
  function openTab(item) {
    const isi = document.getElementById("fixed-isi");
    const tab = document.querySelector("#fixed-isi ." + item);
    const active = document.querySelector("#fixed-isi .col.expand");
    const html = document.querySelector("html");

    if (tab.classList.contains("expand")) {
      isi.classList.remove("expanded");
      tab.classList.remove("expand");
      html.classList.remove("no-scroll");
    } else {
      active.classList.remove("expand");
      tab.classList.add("expand");
    }
  }

  return (
    <div className="content-wrapper">
      <div className="col isi">
        <div className="content">
          <h5 className="custom-large">IMPORTANT SAFETY INFORMATION</h5>
          <div className="mobile-toggle" onClick={() => openTab("isi")}></div>
          <div className="mobile-scroll">
            <h6 className="fixed-custom">
              Before taking KRAZATI, tell your healthcare provider about all of
              your medical conditions, including if you:
            </h6>
            <ul className="nmb">
              <li className="mt">
                have any heart problems, including heart failure and congenital
                long QT syndrome
              </li>
              <li>have liver problems</li>
              <li className="hideOnCollapse">
                are pregnant or plan to become pregnant. It is not known if
                KRAZATI can harm your unborn baby
              </li>
              <li className="hideOnCollapse">
                are breastfeeding or plan to breastfeed. It is not known if
                KRAZATI passes into your breastmilk. Do not{" "}
                <span className="crc landing">breastfeed</span>
                <span className="nsclc">breast feed</span> during treatment and
                for 1 week after your last dose of KRAZATI
              </li>
            </ul>
            <p className="mb gap">
              <strong>
                Tell your healthcare provider about all the medicines you take,
              </strong>{" "}
              including prescription and over-the-counter medicines, vitamins,
              and herbal supplements. KRAZATI can affect the way other medicines
              work, and other medicines can affect how KRAZATI works.
            </p>

            <h6>KRAZATI can cause serious side effects, including:</h6>
            <ul>
              <li className="mt">
                <strong>
                  Stomach and intestinal (gastrointestinal) problems.
                </strong>{" "}
                Stomach and intestinal side effects, including nausea, diarrhea,
                or vomiting, are common with KRAZATI but can also sometimes be
                severe. KRAZATI can also cause serious stomach and intestinal
                side effects such as bleeding, obstruction, inflammation of the
                colon (colitis), and narrowing (stenosis)
              </li>
              <ul>
                <li>
                  <strong>
                    Call your healthcare provider if you develop any of the
                    signs or symptoms of stomach or intestinal problems listed
                    above during treatment with KRAZATI
                  </strong>
                </li>
                <li>
                  Your healthcare provider may prescribe an antidiarrheal
                  medicine or anti-nausea medicine, or other treatment, as
                  needed
                </li>
                {/* <li className="mt mobile-only">
                  <strong>
                    Changes in the electrical activity of your heart called QTc
                    prolongation.
                  </strong>{' '}
                  Certain changes can occur in the electrical activity of your
                  heart during treatment with KRAZATI, and can be seen on a test
                  called an electrocardiogram (ECG or EKG). QTc prolongation can
                  increase your risk for irregular heartbeats that can be
                  life-threatening, such as torsades de pointes, and can lead to
                  sudden death
                </li> */}
              </ul>
              <li className="mt">
                <strong>
                  Changes in the electrical activity of your heart called QTc
                  prolongation.
                </strong>{" "}
                Certain changes can occur in the electrical activity of your
                heart during treatment with KRAZATI and can be seen on a test
                called an electrocardiogram (ECG or EKG). QTc prolongation can
                increase your risk for irregular heartbeats that can be
                life-threatening, such as torsades de pointes, and can lead to
                sudden death
              </li>
              <ul>
                <li>
                  You should not take KRAZATI if you have congenital long QT
                  syndrome or if you currently have QTc prolongation. See{" "}
                  <strong>
                    “Before taking KRAZATI, tell your healthcare provider about
                    all of your medical conditions, including if you:”
                  </strong>
                </li>
                <ul>
                  <li>
                    Your healthcare provider should monitor the electrical
                    activity of your heart and the levels of body salts in your
                    blood (electrolytes){" "}
                    <span className="crc landing">
                      especially potassium and magnesium before starting and
                    </span>{" "}
                    during treatment with KRAZATI if you have heart failure, a
                    slow heart rate, abnormal levels of electrolytes in your
                    blood, or if you take a medicine that can prolong the QT
                    interval of your heartbeat
                  </li>
                  <li>
                    <strong>
                      Tell your healthcare provider if you feel dizzy,
                      lightheaded, or faint, or if you get abnormal heartbeats
                      during treatment with KRAZATI
                    </strong>
                  </li>
                </ul>
              </ul>
              <li className="mt">
                <strong>Liver problems.</strong> Abnormal liver blood test
                results are common with KRAZATI and can sometimes be severe.
                Your healthcare provider should do blood tests before starting
                and during treatment with KRAZATI to check your liver function.
                Tell your healthcare provider right away if you develop any
                signs or symptoms of liver problems, including:
              </li>
              <ul>
                <li>
                  your skin or white part of your eyes turns yellow (jaundice),
                  dark or “tea-colored” urine, light-colored stools (bowel
                  movements), tiredness or weakness, nausea or vomiting,
                  bleeding or bruising, loss of appetite, pain, aching, or
                  tenderness on the right side of your stomach area (abdomen)
                </li>
              </ul>
              <li className="mt">
                <strong>Lung or breathing problems.</strong> KRAZATI may cause
                inflammation of the lungs that can lead to death. Tell your
                healthcare provider or get emergency medical help right away if
                you have new or worsening shortness of breath, cough, or fever
              </li>
            </ul>

            <h6>The most common side effects</h6>
            <ul>
              <li className="mt common-side-effects landing">
                The most common side effects of KRAZATI when used alone for
                NSCLC include: nausea, diarrhea, vomiting, tiredness, muscle and
                bone pain, kidney problems, swelling, decreased appetite,
                trouble breathing
              </li>
              <li className="mt common-side-effects nsclc">
                The most common side effects of KRAZATI when used alone include:
                nausea, diarrhea, vomiting, tiredness, muscle and bone pain,
                kidney problems, liver laboratory test abnormalities, swelling,
                breathing trouble, decreased appetite
              </li>
              <li className="mt common-side-effects crc">
                The most common side effects when KRAZATI is used in combination
                with cetuximab for CRC include: skin rash, nausea, diarrhea,
                vomiting, tiredness, muscle and bone pain, headache, dry skin,
                stomach pain, decreased appetite, swelling, low red blood cell
                count, cough, dizziness, constipation, nerve damage in the arms
                and legs
              </li>
            </ul>
            <p className="mb nsclc">
              Certain abnormal laboratory test results are common with KRAZATI,
              when used alone. Your healthcare provider will monitor you for
              abnormal laboratory tests, and treat you if needed.
            </p>
            <p className="mb crc">
              Certain abnormal blood test results are common during treatment
              with KRAZATI, when used alone or in combination with cetuximab.
              Your healthcare provider will monitor you for abnormal blood tests
              and treat you if needed.
            </p>
            <p className="mb">
              KRAZATI may cause fertility problems in males and females, which
              may affect your ability to have children. Talk to your healthcare
              provider if this is a concern for you.
            </p>
            <p className="mb">
              These are not all <span className="crc landing">of</span> the
              possible side effects of KRAZATI. Call your doctor for medical
              advice about side effects.{" "}
              <span className="crc landing">
                You may report side effects to the FDA. Visit{" "}
                <a className="no-style" href="https://fda.gov/medwatch">
                  fda.gov/medwatch
                </a>{" "}
                or call{" "}
                <a className="no-style" href="tel:+18003321088">
                  <span className="no-wrap">1-800-FDA-1088</span>
                </a>
                . You may also report side effects to{" "}
                <a className="no-style" href="tel:+18446472841">
                  <span className="no-wrap">1-844-647-2841</span>
                </a>
                .
              </span>
              <span className="nsclc">
                You are encouraged to report negative side effects to the FDA.
                Visit{" "}
                <a className="no-style" href="https://fda.gov/medwatch">
                  fda.gov/medwatch
                </a>
                , or call{" "}
                <a className="no-style" href="tel:+18003321088">
                  <span className="no-wrap">1-800-FDA-1088</span>
                </a>
                . You may also contact Mirati Therapeutics, Inc. at
                1-844-MIRATI-1 (
                <a className="no-style" href="tel:+18003321088">
                  <span className="no-wrap">1-844-647-2841</span>
                </a>
                ).
              </span>
            </p>

            <p className="bold-blue prescribing-info">
              <strong>
                Please see KRAZATI{` `}
                <a
                  href="https://www.bms.com/assets/packageinserts/ppi/ppi_krazati.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Patient Information
                </a>
                .
              </strong>
            </p>
          </div>
        </div>
      </div>
      <div className="col indication">
        <div className="landing">
          <h5 className="custom-large">INDICATIONS</h5>
          <div
            className="mobile-toggle"
            onClick={() => {
              openTab("indication");
            }}
          ></div>
          <h6 className="fixed-custom">What is KRAZATI?</h6>
          <p>KRAZATI is a prescription medicine used in adults:</p>
          <ul>
            <li className="mt">
              alone to treat non-small cell lung cancer (NSCLC)
              <ul>
                <li>
                  that has spread to other parts of the body or cannot be
                  removed by surgery, <b>and</b>
                </li>
                <li>
                  whose tumor has an abnormal KRAS G12C gene, <b>and</b>
                </li>
                <li>who have received at least one prior treatment.</li>
              </ul>
            </li>
            <li className="mt">
              in combination with a medicine called cetuximab to treat colon or
              rectal cancer (CRC)
              <ul>
                <li>
                  that has spread to other parts of the body or cannot be
                  removed by surgery, <b>and</b>
                </li>
                <li>
                  whose tumor has an abnormal KRAS G12C gene, <b>and</b>
                </li>
                <li>
                  who have previously received certain chemotherapy medicines.
                </li>
              </ul>
            </li>
          </ul>
          <p className="gap">
            Your healthcare provider will perform a test to make sure that
            KRAZATI is right for you.
          </p>
          <p className="gap">
            It is not known if KRAZATI is safe and effective in children.
          </p>
        </div>
        <div className="nsclc">
          <h5 className="custom-large">INDICATION</h5>
          <div
            className="mobile-toggle"
            onClick={() => {
              openTab("indication");
            }}
          ></div>
          <h6 className="fixed-custom">What is KRAZATI?</h6>
          <p>KRAZATI is a prescription medicine used:</p>
          <ul>
            <li className="mt">
              alone to treat adults with non-small cell lung cancer (NSCLC)
              <ul>
                <li>
                  that has spread to other parts of the body or cannot be
                  removed by surgery, <b>and</b>
                </li>
                <li>
                  whose tumor has an abnormal <em>KRAS G12C</em> gene,{" "}
                  <b>and</b>
                </li>
                <li>who have received at least one prior treatment</li>
              </ul>
            </li>
          </ul>
          <p className="gap">
            Your healthcare provider will perform a test to make sure that
            KRAZATI is right for you.
          </p>
          <p className="gap">
            It is not known if KRAZATI is safe and effective in children.
          </p>
        </div>
        <div className="crc">
          <h5 className="custom-large">INDICATION</h5>
          <button
            className="mobile-toggle"
            onClick={() => {
              openTab("indication");
            }}
          ></button>
          <h6 className="fixed-custom">What is KRAZATI?</h6>
          <p>KRAZATI is a prescription medicine used in adults:</p>
          <ul>
            <li className="mt">
              in combination with a medicine called cetuximab to treat colon or
              rectal cancer (CRC)
              <ul>
                <li>
                  that has spread to other parts of the body or cannot be
                  removed by surgery, <b>and</b>
                </li>
                <li>
                  whose tumor has an abnormal KRAS G12C gene, <b>and</b>
                </li>
                <li>
                  who have previously received certain chemotherapy medicines.
                </li>
              </ul>
            </li>
          </ul>
          <p className="gap">
            Your healthcare provider will perform a test to make sure that
            KRAZATI is right for you.
          </p>
          <p className="gap">
            It is not known if KRAZATI is safe and effective in children.
          </p>
        </div>
      </div>
    </div>
  );
}
